<template>
  <!--begin::Charts Widget 3-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header h-auto border-0">
      <div class="card-title py-5">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder">{{
            $t("analytics.searches_without_results.title")
          }}</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <h3 class="text-danger" v-if="!this.loading">
          <i
            class="fa fa-search text-danger font-weight-bolder font-size-h3 mr-2"
          ></i>
          {{
            parseInt(
              this.total_queries.totals &&
                this.total_queries.totals.queries_wo_results
                ? this.total_queries.totals.queries_wo_results
                : 0
            ).toLocaleString()
          }}
          &nbsp;/&nbsp;
          <i
            class="fa fa-users text-danger font-weight-bolder font-size-h3 mr-2"
          ></i>
          {{
            parseInt(
              this.total_queries.totals &&
                this.total_queries.totals.unique_queries_wo_results
                ? this.total_queries.totals.unique_queries_wo_results
                : 0
            ).toLocaleString()
          }}
        </h3>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body" style="position: relative;">
      <div v-if="this.loading">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="margin:auto;background:#fff;display:block;"
          width="200px"
          height="200px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <g transform="translate(20 50)">
            <circle cx="0" cy="0" r="6" fill="#7b0000">
              <animateTransform
                attributeName="transform"
                type="scale"
                begin="-0.375s"
                calcMode="spline"
                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                values="0;1;0"
                keyTimes="0;0.5;1"
                dur="1s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </g>
          <g transform="translate(40 50)">
            <circle cx="0" cy="0" r="6" fill="#c34c37">
              <animateTransform
                attributeName="transform"
                type="scale"
                begin="-0.25s"
                calcMode="spline"
                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                values="0;1;0"
                keyTimes="0;0.5;1"
                dur="1s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </g>
          <g transform="translate(60 50)">
            <circle cx="0" cy="0" r="6" fill="#fd7f64">
              <animateTransform
                attributeName="transform"
                type="scale"
                begin="-0.125s"
                calcMode="spline"
                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                values="0;1;0"
                keyTimes="0;0.5;1"
                dur="1s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </g>
          <g transform="translate(80 50)">
            <circle cx="0" cy="0" r="6" fill="#ffbea0">
              <animateTransform
                attributeName="transform"
                type="scale"
                begin="0s"
                calcMode="spline"
                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                values="0;1;0"
                keyTimes="0;0.5;1"
                dur="1s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </g>
        </svg>
      </div>
      <div
        v-else-if="
          graphData && graphData[0].data && graphData[0].data.length > 0
        "
      >
        <apexchart
          class="card-rounded-bottom"
          :options="chartOptions"
          :series="graphData"
          type="area"
          height="180"
          width="100%"
        ></apexchart>
      </div>
      <div v-else>
        <div
          class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
        >
          <div class="card-body text-center">
            <div class="row">
              <div class="col-sm-12">
                <h2>
                  {{ $t("analytics.searches_without_results.no_result") }}
                </h2>
                <p>
                  {{
                    $t("analytics.searches_without_results.no_result_subtitle")
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Charts Widget 3-->
    <!--begin::Header-->
    <div
      class="card-header border-0 py-5"
      v-if="
        !this.loading &&
          graphData &&
          graphData[0].data &&
          graphData[0].data.length > 0
      "
    >
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">{{
          $t("analytics.searches_without_results.widget.table.title")
        }}</span>
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <div
      class="card-body pt-0 pb-3"
      v-if="
        !this.loading &&
          graphData &&
          graphData[0].data &&
          graphData[0].data.length > 0
      "
    >
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-head-bg table-vertical-center">
          <thead>
            <tr>
              <th></th>
              <th>
                {{
                  $t(
                    "analytics.searches_without_results.list.table.headers.queries"
                  )
                }}
              </th>
              <th>
                {{
                  $t(
                    "analytics.searches_without_results.list.table.headers.unique_queries"
                  )
                }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in this.total_queries.table" :key="row.row">
              <td>
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-50 symbol-light mr-4">
                    <span class="symbol-label">#{{ row.row }}</span>
                  </div>
                  <div>
                    <router-link
                      class="font-weight-bolder text-danger d-block font-size-lg"
                      :to="{
                        name: 'trending-searches-item',
                        query: { term: row.term }
                      }"
                    >
                      {{ row.term }}
                    </router-link>
                  </div>
                </div>
              </td>
              <td>
                <span class="text-dark-75 font-size-lg font-weight-bolder">{{
                  parseInt(row.queries).toLocaleString()
                }}</span>
              </td>
              <td>
                <span class="text-dark-75 font-size-lg font-weight-bolder">{{
                  parseInt(row.unique_queries).toLocaleString()
                }}</span>
              </td>
              <td align="right">
                <span class="text-dark-75 font-size-lg font-weight-bolder">
                  <router-link
                    v-if="row.fixits > 0"
                    v-slot="{ href }"
                    :to="{
                      name: 'fixit',
                      query: { term: row.term }
                    }"
                  >
                    <a :href="href" class="btn btn-success font-weight-bolder">
                      {{ $t("search_settings.fixit.buttons.show_fixit") }}
                    </a>
                  </router-link>
                  <fixit_add_dialog
                    v-if="row.fixits == 0"
                    disabled="true"
                    :callback="reloadData"
                    :term="row.term"
                    :buttontitle="
                      $t('search_settings.fixit.buttons.fix_this_problem')
                    "
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="3" class="text-center">
                <div class="align-items-center">
                  <router-link
                    class="font-weight-bolder text-danger d-block font-size-lg"
                    style="text-decoration: underline"
                    to="/searches-without-results"
                  >
                    {{ $t("common.show_more") }}
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import { LOAD_TOTAL_QUERIES_WITHOUT_QUERIES } from "../../../../core/services/store/dashboard.module";
import fixit_add_dialog from "../../../pages/fixit/add_dialog";

export default {
  components: { fixit_add_dialog },
  props: {
    filter: {
      type: String,
      default: "search"
    }
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    onDateRangeChanged() {
      this.reloadData();
    },
    filter() {
      this.reloadData();
    }
  },
  computed: {
    project() {
      return this.currentProject.token;
    },
    changeProject() {
      return this.currentProject.token;
    },
    onDateRangeChanged() {
      return this.dateRange;
    },
    graphData() {
      return [
        {
          name: "Total Queries",
          data:
            this.total_queries && this.total_queries.timeline
              ? this.total_queries.timeline.data
              : []
        }
      ];
    },
    ...mapState({
      total_queries: state => state.dashboard.total_queries_without_results,
      loading: state => state.dashboard.loading.total_queries_without_results
    }),
    ...mapGetters(["currentProject", "dateRange"])
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData() {
      const data = [];
      if (this.filter === "search" || this.filter === "all") {
        data.push("result_setting_type_id[]=2");
      }
      if (this.filter === "autocomplete" || this.filter === "all") {
        data.push("result_setting_type_id[]=1");
      }

      if (this.dateRange.startDate) {
        data.push(
          `start=${moment(this.dateRange.startDate).format("yyyy-MM-DD")}`
        );
      }
      if (this.dateRange.endDate) {
        data.push(`end=${moment(this.dateRange.endDate).format("yyyy-MM-DD")}`);
      }
      this.$store.dispatch(LOAD_TOTAL_QUERIES_WITHOUT_QUERIES, data.join("&"));
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          height: 180,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          sparkline: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ["#F64E60"],
        stroke: {
          show: true,
          width: 2
        },
        xaxis: {
          type: "datetime"
        },
        yaxis: {
          title: {
            text: "Queries"
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            show: true,
            format: "dd. MM h:00:00",
            formatter: undefined
          },
          y: {
            formatter: function(val) {
              return parseInt(val).toLocaleString();
            }
          }
        }
      }
    };
  }
};
</script>

<template>
  <!--begin::Dashboard-->
  <div>
    <div class="row">
      <div class="col-xl-8">
        <h2>{{ $t("dashboard.title") }}</h2>
      </div>
      <div class="col-xl-4 ">
        <ul
          class="nav nav-pills nav-pills-sm nav-dark-75 float-right"
          role="tablist"
        >
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              @click.prevent="toggleAll()"
              :class="{ active: this.filter === 'all' }"
            >
              <span class="nav-text font-size-sm">{{
                $t("result_settings.types.all")
              }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              @click.prevent="toggleAutocomplete()"
              :class="{ active: this.filter === 'autocomplete' }"
            >
              <span class="nav-text font-size-sm">{{
                $t("result_settings.types.autocomplete")
              }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-2 px-4"
              @click.prevent="toggleSearch()"
              :class="{ active: this.filter === 'search' }"
            >
              <span class="nav-text font-size-sm">{{
                $t("result_settings.types.search")
              }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6">
        <TrendingSearches :filter="this.filter"></TrendingSearches>
      </div>
      <div class="col-xl-6">
        <TotalQueriesWOResults :filter="this.filter"></TotalQueriesWOResults>
      </div>
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import TrendingSearches from "@/view/content/widgets/analytics/TrendingSearches.vue";
import { mapGetters } from "vuex";
import TotalQueriesWOResults from "../content/widgets/analytics/TotalQueriesWOResults";

export default {
  name: "dashboard",
  components: {
    TotalQueriesWOResults,
    TrendingSearches
  },
  data() {
    return {
      filter: "search"
    };
  },
  computed: {
    project() {
      return this.currentProject.token;
    },
    changeProject() {
      return this.currentProject.token;
    },
    onDateRangeChanged() {
      return this.dateRange;
    },
    ...mapGetters(["currentProject", "dateRange"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    toggleSearch() {
      this.filter = "search";
    },
    toggleAutocomplete() {
      this.filter = "autocomplete";
    },
    toggleAll() {
      this.filter = "all";
    }
  }
};
</script>
